module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/codebuild/output/src899318169/src/gatsby-nnoms/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/Favicon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KTTLRSW","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.northernnevadaoms.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Northern Nevada Oral & Maxillofacial Surgery","lang":"en","display":"standalone","short_name":"Northern Nevada Oral & Maxillofacial Surgery","description":"Christopher Galea, DDS, MD, FACS, is an oral surgeon in Reno and Sparks, NV, who performs a range of oral surgery procedures, including dental implant placement, wisdom teeth removal, jaw surgery, cleft lip and cleft palate treatment, and more.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/Favicon-512x512.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0947227c0bda66d5fcf5541487ee4c2b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
